@font-face{
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Light'), 
         local('Nunito-ReguLightlar'), 
         url('./assets/fonts/nunito-light.woff2') format('woff2'),
         url('./assets/fonts/nunito-light.woff') format('woff');
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Regular'), 
        local('Nunito-Regular'),
        url('./assets/fonts/nunito-regular.woff2') format('woff2'),
        url('./assets/fonts/nunito-regular.woff') format('woff');
}

@font-face{
    font-family: Bitter;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Bitter Regular'), 
         local('Bitter-Regular'), 
         url('./assets/fonts/bitter-regular.woff2') format('woff2'),
         url('./assets/fonts/bitter-regular.woff') format('woff');
}

@font-face {
    font-family: Bitter;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Bitter Bold'), 
        local('Bitter-Bold'),
        url('./assets/fonts/bitter-bold.woff2') format('woff2'),
        url('./assets/fonts/bitter-bold.woff') format('woff');
}
